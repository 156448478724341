@charset "UTF-8";
/*
 * Mind and Life
 */
@import url("https://fonts.googleapis.com/css?family=Open+Sans:400,600,700");
meta.foundation-version {
  font-family: "/{{VERSION}}/";
}

meta.foundation-mq-small {
  font-family: "/only screen/";
  width: 0;
}

meta.foundation-mq-small-only {
  font-family: "/only screen and (max-width: 640px)/";
  width: 0;
}

meta.foundation-mq-medium {
  font-family: "/only screen and (min-width: 641px)/";
  width: 641px;
}

meta.foundation-mq-medium-only {
  font-family: "/only screen and (min-width: 641px) and (max-width: 1000px)/";
  width: 641px;
}

meta.foundation-mq-large {
  font-family: "/only screen and (min-width: 1000px)/";
  width: 1000px;
}

meta.foundation-mq-large-only {
  font-family: "/only screen and (min-width: 1000px) and (max-width: 1440px)/";
  width: 1000px;
}

meta.foundation-mq-xlarge {
  font-family: "/only screen and (min-width: 90.0625rem)/";
  width: 90.0625rem;
}

meta.foundation-mq-xlarge-only {
  font-family: "/only screen and (min-width: 90.0625rem) and (max-width: 120rem)/";
  width: 90.0625rem;
}

meta.foundation-mq-xxlarge {
  font-family: "/only screen and (min-width: 120.0625rem)/";
  width: 120.0625rem;
}

meta.foundation-data-attribute-namespace {
  font-family: bootstrap;
}

section.error-404 .page-wrapper {
  font-size: 1rem;
  text-align: center;
}
section.error-404 .page-wrapper code {
  font-size: 1rem;
}
section.error-404 .page-wrapper h5 {
  border-bottom: 0;
}
section.error-404 .page-wrapper a {
  border-bottom: 0;
}
section.error-404 .page-wrapper ul {
  margin-left: 1rem;
}
section.error-404 .page-wrapper ul li {
  padding-bottom: 0.375rem;
}

.blog-article-short {
  position: relative;
}
.blog-article-short:before {
  position: absolute;
  content: " ";
  background: white;
  height: 100%;
  width: 200vw;
  left: -100vw;
}
@media only screen and (max-width: 640px) {
  .blog-article-short {
    padding-left: 0.9375rem;
    padding-right: 0.9375rem;
  }
}
.blog-article-short .blog-image {
  text-align: center;
}

.blog-article-sub-nav {
  font-family: "Open Sans", Arial, Times New Roman;
  color: #646464;
  padding: 0.9375rem 0 !important;
}
.blog-article-sub-nav .column {
  font-size: 0.875rem !important;
}

.blog-article-content {
  margin-top: 0.9375rem !important;
  padding: 0 1.875rem !important;
}

.read-more {
  padding: 0 1.875rem !important;
}
.read-more a {
  border-bottom: none;
  font-size: 1rem;
  font-weight: 700;
  font-family: "Open Sans", Arial, Times New Roman;
  color: #b11117;
}
.read-more a:hover, .read-more a:focus, .read-more a:active {
  color: #c81b21;
}

.box.box-image {
  position: relative;
  width: 100%;
  padding-bottom: 100%;
  max-height: 100%;
  overflow: hidden;
  font-size: 0;
  background-size: cover;
  background-position: center center;
}
.box.box-image.box-image-3-333-to-1 {
  padding-bottom: 30.0%;
}
.box.box-image .content {
  position: absolute;
  font-size: 1rem;
  top: 0;
  left: 0;
  padding: .4rem 1rem;
}
.box.box-image .content h3 {
  font-size: 1.4rem;
  color: white;
}
@media only screen and (max-width: 1000px) {
  .box.box-image .content h3 {
    font-size: 1.3rem;
  }
}
.box.box-image.alternate h3 {
  color: black;
}
.box.box-contact .icon-text-wrapper {
  line-height: 3rem;
  font-size: 1.5rem;
  font-family: "Open Sans", Arial;
}
.box.box-contact .icon-text-wrapper .icon-font-circle {
  display: inline-block;
  border-radius: 100%;
  background: #b11117;
  height: 3rem;
  width: 3rem;
  text-align: center;
  line-height: 3rem;
  font-size: 1.8rem;
  margin-right: 0.9375rem;
}
.box.box-contact .icon-text-wrapper .icon-font-circle.fix {
  line-height: 2.8rem;
}
.box.box-contact .icon-text-wrapper .icon-font-circle * {
  color: #fff;
  line-height: 3rem !important;
}
.box.box-contact .box-contact-content {
  display: block;
  color: #b6b6b6;
  padding: 0.9375rem 0 0 4.25rem;
}

div.people-section-title {
  margin-top: 1rem;
  padding: .5rem;
  position: relative;
  padding-bottom: 1rem;
}
div.people-section-title span.sub-heading {
  padding-left: 0.4rem;
}

.person {
  margin: 0.5rem;
  border: 1px #cacaca solid;
  border-radius: 4px;
  display: inline-block;
  width: 97%;
}

.person-portrait {
  width: 10rem;
  height: 13rem;
  display: inline-block;
  background-size: cover;
  background-repeat: no-repeat;
  display: inline-block;
  margin-bottom: -3px;
}
@media only screen and (max-width: 640px) {
  .person-portrait {
    display: block;
    margin: 0 auto;
  }
}
@media only screen and (min-width: 641px) and (max-width: 1000px) {
  .person-portrait {
    display: block;
    margin: 0 auto;
  }
}

.person-details {
  width: auto;
  display: inline-block;
  padding: 1rem;
  vertical-align: bottom;
}
.person-details span {
  display: block;
}
.person-details span.name {
  color: #b11117;
  font-weight: bold;
}
@media only screen and (max-width: 640px) {
  .person-details {
    padding-top: 1rem;
    display: block;
    width: 100%;
  }
  .person-details span, .person-details a {
    text-align: center;
  }
}
@media only screen and (min-width: 641px) and (max-width: 1000px) {
  .person-details {
    padding-top: 1rem;
    display: block;
    width: 100%;
  }
  .person-details span, .person-details a {
    text-align: center;
  }
}
@media only screen and (min-width: 1000px) {
  .person-details {
    width: calc(100% - 10rem) !important;
    padding-left: 2rem;
    padding-bottom: 2rem;
  }
}

.workfield {
  width: 100%;
  display: inline-block;
  font-size: 1rem;
}

.workfield-title {
  background: #b11117;
  display: block;
  width: 100%;
  font-size: 1.5rem;
  padding: .5rem;
  text-align: center;
  font-weight: bold;
  height: 3rem;
}
.workfield-title p {
  color: white;
  line-height: 3rem;
}

.workfield-achievement {
  background: #ebebeb;
  display: block;
  width: 100%;
  text-align: left;
}
.workfield-achievement ul {
  padding: .5rem 1rem;
}
.workfield-achievement ul li {
  width: 100%;
  padding-left: 1.7rem !important;
}
.workfield-achievement ul li:before {
  content: "\f00c" !important;
  font-family: "FontAwesome" !important;
  color: #b11117 !important;
}

a.button, input[type=submit], label.uploadFile {
  display: inline-block;
  padding: 0.46875rem 1.875rem;
  background: #b11117;
  color: white !important;
  font-family: Verdana, Arial, Arial;
  border: 1px solid rgba(177, 17, 23, 0.5);
  margin: 0.46875rem 0;
  font-size: 1rem;
  font-weight: bold;
  transition: none;
  cursor: pointer;
  transition: all 300ms ease-in-out;
  z-index: 0;
  overflow: hidden;
  position: relative;
}
a.button:hover, a.button:active, a.button:focus, input[type=submit]:hover, input[type=submit]:active, input[type=submit]:focus, label.uploadFile:hover, label.uploadFile:active, label.uploadFile:focus {
  color: #b11117 !important;
  background: white;
  border: 1px solid rgba(177, 17, 23, 0.5);
}
a.button.button-full-width, input[type=submit].button-full-width, label.uploadFile.button-full-width {
  display: block;
  clear: both;
  width: 100%;
  text-align: center;
  padding: 0.46875rem 0.9375rem;
}
a.button.button-red, input[type=submit].button-red, label.uploadFile.button-red {
  background: #631010;
  color: white !important;
}
a.button.button-red:hover, input[type=submit].button-red:hover, label.uploadFile.button-red:hover {
  background: white;
}

label.uploadFile {
  padding: 0.46875rem 3.75rem !important;
  width: auto;
  text-align: center;
}

@-webkit-keyframes wave-material {
  0% {
    transform: scale(0);
    opacity: 1;
  }
  90% {
    opacity: .0;
  }
  100% {
    transform: scale(1);
  }
}
@-moz-keyframes wave-material {
  0% {
    transform: scale(0);
    opacity: 1;
  }
  90% {
    opacity: .0;
  }
  100% {
    transform: scale(1);
  }
}
@-o-keyframes wave-material {
  0% {
    transform: scale(0);
    opacity: 1;
  }
  90% {
    opacity: .0;
  }
  100% {
    transform: scale(1);
  }
}
@keyframes wave-material {
  0% {
    transform: scale(0);
    opacity: 1;
  }
  90% {
    opacity: .0;
  }
  100% {
    transform: scale(1);
  }
}
.material-click-action {
  overflow: hidden;
}

.material-click {
  position: absolute;
  background: #b11117;
  z-index: -1;
  border-radius: 50%;
  transform: scale(0);
  opacity: 0;
}
.material-click.animate {
  -webkit-animation: wave-material 1s 1;
  -moz-animation: wave-material 1s 1;
  -ms-animation: wave-material 1s 1;
  -o-animation: wave-material 1s 1;
  animation: wave-material 1s 1;
}

.material-click-content {
  z-index: 1;
}

article + h1, article + h2, article + h3, article + h4, article + h5, article + h6 {
  margin-top: 1.875rem;
}
article + article {
  margin-top: 1.875rem;
}

.standalone-gallery {
  margin: 2.8125rem 0;
}
.standalone-gallery div {
  padding: 5px;
  text-align: center;
}
.standalone-gallery div img {
  max-height: 400px;
  display: inline-block;
}

.news .news-subinfo {
  font-size: .9rem;
  color: #646464;
  margin-top: -0.46875rem;
  margin-bottom: 0.9375rem;
  display: block;
}

.float-image {
  max-width: 40%;
  margin-left: 1rem;
  float: right;
  margin-bottom: 1rem;
}

.ce-outer {
  text-align: center;
}

.ce-row, .ce-column {
  display: inline-block;
}

.ce-column {
  margin: 25px;
}

fieldset {
  border: 0;
  padding: 0;
}

input {
  border-radius: 0;
  width: 100%;
  -webkit-appearance: none;
  font-size: 1rem;
  padding: 0.46875rem 0.234375rem;
  margin: 0.46875rem 0;
  border: 1px solid rgba(224, 160, 162, 0.9);
}
input[type=text], input[type=password], input[type=url], input[type=email], input[type=search], input[type=number] {
  width: 100%;
}
input[type=submit] {
  margin-top: 1.875rem;
}
input::-webkit-input-placeholder {
  color: #7f7f7f;
}
input:-moz-placeholder {
  color: #7f7f7f;
}
input::-moz-placeholder {
  color: #7f7f7f;
}
input:-ms-input-placeholder {
  color: #7f7f7f;
}
input + p {
  margin-top: 0.9375rem;
}

textarea {
  border: 1px solid rgba(224, 160, 162, 0.9);
}

button {
  border-radius: 0;
}

textarea, select {
  width: 100%;
  padding: 0.46875rem 0.234375rem;
}

label, span.label {
  color: black;
  display: block;
  margin-top: 0.9375rem;
  margin-bottom: 0.234375rem;
}

input[type=checkbox], input[type=radio] {
  position: fixed;
  left: -999px;
  top: -999px;
}
input[type=checkbox] + label, input[type=radio] + label {
  line-height: 1.5rem;
  display: inline-block;
  position: relative;
  margin-right: 1rem;
  margin-top: .5rem;
  cursor: pointer;
}
input[type=checkbox] + label:before, input[type=radio] + label:before {
  position: relative;
  content: " ";
  padding: .7rem;
  vertical-align: bottom;
  display: inline-block;
  background: #fff;
  border: 1px solid #646464;
  margin-right: .5rem;
}
input[type=checkbox] + label:after, input[type=radio] + label:after {
  position: absolute;
  left: 0.125rem;
  top: 0;
  height: 1rem;
  width: 1rem;
  font-size: 1.2rem;
  font-family: FontAwesome;
  color: white;
  content: "\f00c";
  opacity: 0;
  transform: scale(2);
  transition: all ease-in-out 200ms;
}
input[type=checkbox]:checked + label:after, input[type=radio]:checked + label:after {
  opacity: 1;
  transform: scale(1);
}

input[type=radio] + label:before {
  border-radius: 50%;
}
input[type=radio] + label:after {
  content: "\f111";
  left: .35rem;
  font-size: 1rem;
  transform: scale(1.5);
  text-shadow: 0 0 2px white;
}

.validate-error span {
  display: block;
  color: white;
  margin-top: 0.5rem;
}

.confirm-window {
  position: fixed;
  height: 100%;
  width: 100%;
  background: rgba(100, 100, 100, 0.7);
  top: 0;
  left: 0;
  z-index: 1000;
}
.confirm-window .content {
  position: absolute;
  left: 50%;
  top: 50%;
  height: 140px;
  width: 410px;
  background: #fff;
  padding: 2rem;
  text-align: center;
  transform: translate(-50%, -50%);
  box-shadow: 0 5px 4px -2px #646464, 0 0 4px #646464;
}
@media only screen and (max-width: 640px) {
  .confirm-window {
    height: 216px;
    width: 300px;
  }
  .confirm-window a {
    width: 100%;
  }
}

.extbase-debugger-tree input {
  top: 0 !important;
  left: 0 !important;
}

.typo3-message .message-body {
  font-size: 1rem;
}

.svg-icon {
  display: inline-block;
  height: 1rem;
  width: 1rem;
  padding: 0.3125rem;
  background-size: contain;
  vertical-align: middle;
  margin: 0 0.3125rem;
}

.svg-icon-nav {
  height: 2.1875rem;
  width: 2.1875rem;
}
@media (max-width: 480px) {
  .svg-icon-nav {
    height: 1.5625rem;
    width: 1.5625rem;
  }
}

.svg-icon-1-2_5x {
  height: 1.25rem;
  width: 1.25rem;
  margin: 0 0.375rem;
}

.svg-icon-1-5x {
  height: 1.5rem;
  width: 1.5rem;
  margin: 0 0.5rem;
}

.svg-icon-2x {
  height: 2rem;
  width: 2rem;
  margin: 0 0.625rem;
}

.icon-search {
  background-image: url("../images/icons/search.svg");
}

.icon-twitter {
  background-image: url("../images/icons/twitter.svg");
}

.icon-facebook {
  background-image: url("../images/icons/facebook.svg");
}

.icon-linkedin {
  background-image: url("../images/icons/linkedin.svg");
}

.icon-xing {
  background-image: url("../images/icons/xing.svg");
}

.icon-email {
  background-image: url("../images/icons/email.svg");
}

.icon-google-plus {
  background-image: url("../images/icons/google-plus.svg");
}

@media only screen and (max-width: 640px) {
  .icon- {
    background-image: url("../images/icons/_mobile.svg");
  }
}
@-webkit-keyframes signet-spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
@-moz-keyframes signet-spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
@-o-keyframes signet-spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
@keyframes signet-spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.background {
  position: fixed;
  height: 100%;
  width: 100%;
  left: 0;
  top: 0;
  z-index: 0;
}

.background-light-gray {
  background: #f5f5f5;
}

.row {
  max-width: 75rem;
  margin: 0 auto;
  width: 100%;
  display: inline-block;
  text-align: center;
  font-size: 0;
}
.row.full-height, .row .full-height {
  height: 100%;
}
.row.ns, .row.no-space, .row.no-margin {
  margin: 0;
  padding: 0;
}
.row.ns + .ns, .row.ns + .no-space, .row.ns + .no-margin, .row.no-space + .ns, .row.no-space + .no-space, .row.no-space + .no-margin, .row.no-margin + .ns, .row.no-margin + .no-space, .row.no-margin + .no-margin {
  margin-top: 0;
}
.row.row-no-outer-padding {
  padding-bottom: 0.9375rem;
}
.row.row-no-outer-padding .column:first-child {
  padding-left: 0;
  padding-right: 1.875rem;
}
.row.row-no-outer-padding .column:last-child {
  padding-right: 0;
  padding-left: 1.875rem;
}
@media only screen and (max-width: 640px) {
  .row.row-no-outer-padding .column:last-child, .row.row-no-outer-padding .column:first-child {
    padding-left: 0;
    padding-right: 0;
  }
  .row.row-no-outer-padding .column {
    padding: 0;
  }
}
@media only screen and (max-width: 1000px) {
  .row.row-m-auto {
    display: inline-block;
    width: auto;
    margin-top: 0.234375rem !important;
  }
}

.row {
  display: block;
}
@media only screen and (min-width: 1000px) {
  .row .column.column-same-height {
    padding-bottom: 1000rem;
    margin-bottom: -1000rem;
    padding-top: 1000rem;
    margin-top: -1000rem;
  }
}
.row.xl-inline {
  display: inline-block;
}

section + article, article + section {
  margin-top: 1.875rem;
}

section {
  background: #fff;
  margin: 0.9375rem 0;
  box-shadow: 0 4px 4px -2px #646464;
}
section article {
  box-shadow: none;
  border: none;
}

.split article {
  padding: 1rem;
  margin: 0.9375rem 0;
  background: #ebebeb;
  border: 1px solid whitesmoke;
  box-shadow: 0 4px 4px -2px #646464;
}
.split .column:first-child article {
  margin-right: .5rem;
}
.split:last-child article {
  margin-left: .5rem;
}
.split.column-s-100 {
  margin-right: 0 !important;
  margin-left: 0 !important;
}

.row + .row {
  margin-top: 0.9375rem;
}

.column {
  position: relative;
  vertical-align: top;
  padding: 0.9375rem;
  display: inline-block;
  font-size: 1rem;
  text-align: left;
  word-break: break-word;
}
@media only screen and (max-width: 640px) {
  .column {
    padding: 0.46875rem;
  }
}
.column.no-padding {
  padding: 0 0;
}
.column.no-side-padding {
  padding-left: 0;
  padding-right: 0;
}
.column.center {
  text-align: center;
}
.column.bottom {
  vertical-align: bottom;
}
.column.middle {
  vertical-align: middle;
}
.column.top {
  vertical-align: top;
}
.column.left {
  text-align: left;
}
.column.center {
  text-align: center;
}
.column.right {
  text-align: right;
}
.column .top-border {
  border-top: 1px solid #646464;
}

.column-center {
  left: 50%;
  transform: translateX(-50%);
}

.column-5 {
  width: 5%;
}

.column-10 {
  width: 10%;
}

.column-15 {
  width: 15%;
}

.column-20 {
  width: 20%;
}

.column-25 {
  width: 25%;
}

.column-30 {
  width: 30%;
}

.column-35 {
  width: 35%;
}

.column-40 {
  width: 40%;
}

.column-45 {
  width: 45%;
}

.column-50 {
  width: 50%;
}

.column-55 {
  width: 55%;
}

.column-60 {
  width: 60%;
}

.column-65 {
  width: 65%;
}

.column-70 {
  width: 70%;
}

.column-75 {
  width: 75%;
}

.column-80 {
  width: 80%;
}

.column-85 {
  width: 85%;
}

.column-90 {
  width: 90%;
}

.column-95 {
  width: 95%;
}

.column-100 {
  width: 100%;
}

.column-33 {
  width: 33.33333333%;
}

.column-66 {
  width: 66.66666667%;
}

@media only screen and (max-width: 1000px) {
  .column {
    margin-top: auto;
    margin-bottom: auto;
  }

  .column-m-5 {
    width: 5%;
    text-align: left;
  }

  .column-m-10 {
    width: 10%;
    text-align: left;
  }

  .column-m-15 {
    width: 15%;
    text-align: left;
  }

  .column-m-20 {
    width: 20%;
    text-align: left;
  }

  .column-m-25 {
    width: 25%;
    text-align: left;
  }

  .column-m-30 {
    width: 30%;
    text-align: left;
  }

  .column-m-35 {
    width: 35%;
    text-align: left;
  }

  .column-m-40 {
    width: 40%;
    text-align: left;
  }

  .column-m-45 {
    width: 45%;
    text-align: left;
  }

  .column-m-50 {
    width: 50%;
    text-align: left;
  }

  .column-m-55 {
    width: 55%;
    text-align: left;
  }

  .column-m-60 {
    width: 60%;
    text-align: left;
  }

  .column-m-65 {
    width: 65%;
    text-align: left;
  }

  .column-m-70 {
    width: 70%;
    text-align: left;
  }

  .column-m-75 {
    width: 75%;
    text-align: left;
  }

  .column-m-80 {
    width: 80%;
    text-align: left;
  }

  .column-m-85 {
    width: 85%;
    text-align: left;
  }

  .column-m-90 {
    width: 90%;
    text-align: left;
  }

  .column-m-95 {
    width: 95%;
    text-align: left;
  }

  .column-m-100 {
    width: 100%;
    text-align: left;
  }

  .column-m-0 {
    display: none;
  }

  .column-m-auto {
    width: auto;
    display: inline-block;
  }

  .center-m {
    text-align: center;
  }
}
@media only screen and (max-width: 640px) {
  .column + .column-s-100 {
    margin-top: 0.234375rem;
  }

  .column-s-5 {
    width: 5%;
    text-align: left;
  }

  .column-s-10 {
    width: 10%;
    text-align: left;
  }

  .column-s-15 {
    width: 15%;
    text-align: left;
  }

  .column-s-20 {
    width: 20%;
    text-align: left;
  }

  .column-s-25 {
    width: 25%;
    text-align: left;
  }

  .column-s-30 {
    width: 30%;
    text-align: left;
  }

  .column-s-35 {
    width: 35%;
    text-align: left;
  }

  .column-s-40 {
    width: 40%;
    text-align: left;
  }

  .column-s-45 {
    width: 45%;
    text-align: left;
  }

  .column-s-50 {
    width: 50%;
    text-align: left;
  }

  .column-s-55 {
    width: 55%;
    text-align: left;
  }

  .column-s-60 {
    width: 60%;
    text-align: left;
  }

  .column-s-65 {
    width: 65%;
    text-align: left;
  }

  .column-s-70 {
    width: 70%;
    text-align: left;
  }

  .column-s-75 {
    width: 75%;
    text-align: left;
  }

  .column-s-80 {
    width: 80%;
    text-align: left;
  }

  .column-s-85 {
    width: 85%;
    text-align: left;
  }

  .column-s-90 {
    width: 90%;
    text-align: left;
  }

  .column-s-95 {
    width: 95%;
    text-align: left;
  }

  .column-s-100 {
    width: 100%;
    text-align: left;
  }

  .column-s-50 {
    width: 50%;
    text-align: left;
    margin-top: 1rem;
  }
  .column-s-50:nth-child(n+3) {
    margin-top: 1.5rem;
  }

  .column-s-full {
    padding: 0;
  }

  .column-s-0 {
    display: none;
  }
  .column-s-0 + .column-s-100:first-child {
    margin-top: 0rem;
  }

  .s-hide {
    display: none;
  }
}
.row + .row {
  margin-top: 0.9375rem;
}

@media only screen and (min-width: 641px) and (max-width: 1000px) {
  .m-hide {
    display: none;
  }
}
@media only screen and (min-width: 1000px) {
  .l-hide {
    display: none;
  }
}
footer.page-footer {
  width: 100%;
  color: white;
  position: relative;
  overflow: hidden;
  text-align: center;
  z-index: 100;
  background: #646464;
}
footer.page-footer .row + .row {
  margin-top: 2.8125rem;
}
footer.page-footer a {
  width: 100%;
  font-size: .8rem;
  text-decoration: none;
  color: #fff !important;
  border-bottom: 0 !important;
}
footer.page-footer p {
  color: white;
  font-size: .8rem;
}
footer.page-footer .copyright, footer.page-footer .short-links, footer.page-footer .social-media {
  width: 100%;
  color: #b2a7a8;
  padding: 1.25rem 0;
  text-align: center;
}
footer.page-footer .copyright a, footer.page-footer .short-links a, footer.page-footer .social-media a {
  color: white;
}

.divider {
  width: 100%;
  height: 2px;
  margin: 1rem 0;
}

.brand {
  margin: auto;
  height: 51px;
  width: 140px;
  float: right;
  margin-right: 10px;
  margin-left: -.5rem;
  background: url("../images/icons/") no-repeat right center;
  background-size: contain;
}

.show-only-s {
  display: none;
}
@media only screen and (max-width: 640px) {
  .show-only-s {
    display: block;
  }
}

.show-only-m {
  display: none;
}
@media only screen and (min-width: 641px) and (max-width: 1000px) {
  .show-only-m {
    display: block;
  }
}

.show-only-m-s {
  display: none;
}
@media only screen and (max-width: 1000px) {
  .show-only-m-s {
    display: block;
  }
}

.show-only-l {
  display: none;
}
@media only screen and (min-width: 1000px) {
  .show-only-l {
    display: block;
  }
}

.hero {
  position: relative;
}
.hero .image {
  max-width: 100%;
  height: 400px;
  background-position: center center;
  background-size: cover;
}
@media only screen and (max-width: 1000px) {
  .hero .image {
    height: 35.0877192982vw;
    width: 100vw;
  }
}
.hero h1 {
  position: absolute;
  left: 2rem;
  max-width: 50vw;
  bottom: 0;
  padding: 2rem;
  box-sizing: content-box;
  color: white;
  font-size: 2rem;
}
@media only screen and (max-width: 640px) {
  .hero h1 {
    font-size: 1.5rem;
    left: 1rem;
    padding: 1rem;
  }
}
@media only screen and (max-width: 640px) {
  .hero h1 {
    position: relative;
    color: #000;
    padding: 0;
    width: 100%;
    max-width: 100%;
    left: 0;
  }
}
.hero .page-wrapper h1 {
  left: 0;
  padding: 0 1.875rem 2rem 1.875rem;
}

.page-wrapper.page-menu {
  max-width: 75rem;
  padding: 0 1.875rem;
  text-align: right;
}
@media only screen and (max-width: 1000px) {
  .page-wrapper.page-menu {
    padding: 0;
  }
}

nav {
  position: fixed;
  background: white;
  height: 7rem;
  width: 100%;
  padding: .5rem;
  text-align: center;
  z-index: 1000;
  text-transform: uppercase;
  font-family: "Open Sans", Arial;
  font-weight: 700;
}
@media only screen and (max-width: 1000px) {
  nav {
    height: 5.5rem;
  }
}
nav > .page-wrapper > a {
  float: left;
  line-height: 5rem;
}
nav a, nav img {
  height: 4rem;
  position: relative;
  vertical-align: bottom;
}
@media only screen and (max-width: 1000px) {
  nav img {
    vertical-align: top;
    height: 3.5rem;
  }
}
@media only screen and (max-width: 640px) {
  nav img {
    vertical-align: top;
    height: 2.5rem;
    padding-top: .25rem;
  }
}
nav .menu-icon {
  position: relative;
  right: .0rem;
  top: .3rem;
  color: black;
  cursor: pointer;
  display: none;
}
@media only screen and (max-width: 1000px) {
  nav .menu-icon {
    display: inline-block;
  }
}
nav .menu-icon .open {
  display: block;
}
nav .menu-icon .close {
  display: none;
}
nav .menu-icon.close-menu .open {
  display: none;
}
nav .menu-icon.close-menu .close {
  display: block;
}
nav .menu {
  margin-left: 1.25rem;
  display: inline-block;
  line-height: 5rem;
  z-index: 1000;
}
nav .menu .active > a {
  color: #b11117 !important;
}
nav .menu > ul {
  display: inline-block;
  vertical-align: bottom;
}
@media only screen and (min-width: 1000px) {
  nav .menu {
    position: relative;
  }
}
@media only screen and (max-width: 1000px) {
  nav .menu {
    overflow-y: scroll;
  }
}
nav .menu ul {
  margin: 0;
  line-height: 24px;
}
nav .menu ul li {
  position: relative;
  display: inline-block;
  color: white;
  text-align: left;
}
nav .menu ul li a {
  color: black;
  margin-right: 0.46875rem;
}
nav .menu ul li ul {
  display: block;
}
nav .menu ul li ul li {
  display: block;
}
@media only screen and (min-width: 1000px) {
  nav .menu ul li ul {
    line-height: 0;
    display: none;
    border: 1px solid #b11117;
  }
  nav .menu ul li ul li {
    white-space: nowrap;
  }
  nav .menu ul li ul li a {
    line-height: 3.5rem;
    padding: .25rem .5rem;
    margin: 0;
    display: block;
  }
  nav .menu ul li ul li a:after {
    border-bottom: 0;
  }
  nav .menu ul li ul li ul {
    display: none !important;
    box-shadow: 0 4px 2px -2px #555 !important;
  }
  nav .menu ul li ul li:hover {
    background: #ebebeb;
  }
  nav .menu ul li ul li:hover ul {
    display: block !important;
    top: -1px;
    right: auto;
    left: 100%;
    padding: 0;
    margin: 0;
    white-space: nowrap;
  }
  nav .menu ul li:hover ul {
    display: block;
    display: block;
    position: absolute;
    background: white;
    top: 100%;
    left: 0;
    padding-top: 0rem;
    color: #000;
    border: 1px solid #ebebeb;
    box-shadow: 2px 4px 2px -2px #555;
  }
  nav .menu ul li:hover ul a {
    color: #000;
  }
}
@media only screen and (max-width: 1000px) {
  nav .menu {
    display: none;
    background: #b6b6b6;
    height: 100%;
    width: 100%;
    left: 0;
    top: 4rem;
    padding-bottom: 4rem;
    margin: 0;
    position: fixed;
  }
  nav .menu.show {
    display: block;
  }
  nav .menu ul {
    line-height: 3rem;
    display: block;
  }
  nav .menu ul li {
    display: block;
    background: white;
    padding: 0rem;
  }
  nav .menu ul li a {
    color: black;
    text-indent: 2rem;
    margin-right: 0rem;
    display: block;
    border-bottom: 1px solid white;
  }
  nav .menu ul li a:hover {
    border-bottom: 1px solid white;
  }
  nav .menu ul li.sub-menu > .drop-down {
    cursor: pointer;
    position: absolute;
    right: 0;
    top: 0;
    height: 3rem;
    width: 3rem;
    font-size: 2rem;
    background: white;
    color: #b11117;
    text-align: center;
    z-index: 9999;
    border-bottom: 1px solid white;
  }
  nav .menu ul li.sub-menu > .drop-down:after {
    font-family: Arial;
    content: "+";
  }
  nav .menu ul li.sub-menu.open > .drop-down:after {
    font-family: Arial;
    content: "-";
  }
  nav .menu ul li.sub-menu.open > ul {
    display: block;
  }
  nav .menu ul li.sub-menu .sub-menu .drop-down {
    background: #ebebeb;
    border-bottom: #ebebeb;
  }
  nav .menu ul li ul {
    display: none;
  }
  nav .menu ul li ul li {
    background: #ebebeb;
  }
  nav .menu ul li ul li a {
    text-indent: 4rem;
  }
  nav .menu ul li ul li ul li {
    background: #b6b6b6;
  }
  nav .menu ul li ul li ul li a {
    text-indent: 6rem;
  }
}
nav .menu .language-switch a {
  color: black;
  font-weight: 400;
  text-transform: lowercase;
}
nav .menu .language-switch a.active {
  font-weight: 700;
}
@media only screen and (min-width: 1000px) {
  nav .menu .language-switch {
    position: absolute;
    top: 0;
    right: 0;
    height: 2rem;
    line-height: 2rem;
    text-align: right;
  }
}
@media only screen and (max-width: 1000px) {
  nav .menu .language-switch {
    position: relative;
    text-align: center;
    width: 100%;
  }
}

html {
  box-sizing: border-box;
  font-family: sans-serif;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  overflow-x: hidden;
}

*,
*:before,
*:after {
  box-sizing: inherit;
}

html,
body {
  font-family: Verdana, Arial, Arial;
  font-size: 16px;
  height: 100%;
  margin: 0;
  background: #e6e6e6;
  color: black;
  word-break: break-word;
  width: 100%;
}

body {
  padding: 0px 0 0 0;
}

[hidden],
template {
  display: none;
}

article,
aside,
details,
figcaption,
figure,
footer,
header,
main,
nav,
section,
summary {
  display: block;
}

header {
  position: relative;
}

audio,
canvas,
progress,
video {
  display: inline-block;
  vertical-align: baseline;
  max-width: 100%;
}

abbr[title] {
  border-bottom: 1px dotted;
  cursor: help;
}

address {
  font-style: normal;
}

b,
strong {
  font-weight: bold;
}

small {
  font-size: 80%;
}

sub,
sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline;
}

sup {
  top: -0.5em;
}

sub {
  bottom: -0.25em;
}

a {
  background: transparent;
  text-decoration: none;
  font-style: normal;
  color: #b11117;
}
a:visited {
  color: #b11117;
}
a.current {
  border-bottom: 1px solid #d8888b;
  font-weight: 600;
}

.content a, p a {
  color: #b11117;
  transition: color 0.3s ease-in-out;
}
.content a:hover, .content a:focus, p a:hover, p a:focus {
  color: #c81b21;
  cursor: pointer;
}
.content a:active, .content a:hover, p a:active, p a:hover {
  outline: 0;
}

figure {
  margin: 0;
}

img {
  display: inline-block;
  max-width: 100%;
  height: auto;
  border: 0;
  vertical-align: middle;
  -ms-interpolation-mode: bicubic;
}

hr {
  box-sizing: content-box;
  border: 0;
  height: 2px;
  background: #b11117;
  margin: 0.9375rem 0;
}

iframe {
  max-width: 100%;
}

svg:not(:root) {
  overflow: hidden;
}

p {
  margin: 0;
  transform: translateZ(0px);
}
p + p {
  margin: 0.9375rem 0 0;
}

blockquote {
  position: relative;
  display: inline-block;
  background: #ebebeb;
  padding: 1rem 1rem 1rem 3rem;
  margin-top: 0.9375rem;
}
blockquote:before {
  left: 0.9375rem;
  font-size: 1.25rem;
  position: absolute;
  content: "\f10e";
  color: #646464;
  font-family: "FontAwesome";
  width: 2rem;
}
blockquote:after {
  position: absolute;
  content: " ";
  top: 0;
  left: 0;
  height: 100%;
  width: .25rem;
  background: #b11117;
}

.gray {
  color: #646464 !important;
}

@media only screen and (max-width: 1000px) {
  .noscroll {
    position: fixed;
    overflow: hidden !important;
  }
}

.flash-message {
  position: fixed;
  top: 30%;
  left: 50%;
  width: 500px;
  padding: 20px;
  margin-left: -250px;
  border: 1px solid #b6b6b6;
  box-shadow: 0 5px 4px -2px #646464, 0 0 4px #646464;
  background: #fff;
  transition: opacity 900ms ease-in-out;
}
@media only screen and (max-width: 640px) {
  .flash-message {
    width: 95%;
    left: 2.5%;
    margin-left: 0;
  }
}

.search-field {
  display: none;
  position: absolute;
  width: 100%;
  right: 0;
  bottom: 0;
  height: 3rem;
  z-index: 1000;
  background: #fff;
}
.search-field.active {
  display: block;
}
.search-field input {
  border: 0;
  border-bottom: 1px solid #ccc;
  outline: none;
  padding: 0.5rem 0.234375rem;
}
.search-field input[type=submit] {
  position: absolute;
  right: 0;
  width: 100px;
  text-align: center;
  padding: .5rem;
  margin: 0;
  bottom: -.75rem;
}
@media only screen and (max-width: 1000px) {
  .search-field {
    display: block;
    position: relative;
    text-align: center;
    height: 5rem;
    background: #fff;
  }
  .search-field form {
    position: relative;
    display: inline-block;
    width: 93%;
  }
  .search-field form input[type=submit] {
    bottom: 1.2rem;
  }
}

.search {
  display: inline-block;
  margin-left: 0.9375rem;
  vertical-align: bottom;
  line-height: 24px;
  padding-bottom: 3px;
  width: 1.25rem;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  cursor: pointer;
}
.search .fa {
  font-size: 20px;
  display: none;
}
.search .active {
  display: block;
}
@media only screen and (max-width: 1000px) {
  .search {
    display: none !important;
  }
}

/* KE Search */
.kesearch_searchbox .kesearchbox {
  position: relative;
}
.kesearch_searchbox .kesearchbox .reset {
  position: absolute;
  top: 0.46875rem;
  right: 0.46875rem;
  line-height: 2rem;
  cursor: pointer;
  opacity: .4;
  transition: opacity 300ms ease-in-out;
}
.kesearch_searchbox .kesearchbox .reset:hover {
  opacity: 1;
}
.kesearch_searchbox .kesearchbox .reset:after {
  font-family: 'FontAwesome';
  content: "\f00d";
  font-size: 1rem;
  color: #b11117;
}
.kesearch_searchbox .submitbutt input[type=submit] {
  margin-top: 0.9375rem;
  margin-bottom: 0.9375rem;
}

.tx-kesearch-pi1 .messsage {
  padding: 0.9375rem 0;
}
.tx-kesearch-pi1 #kesearch_num_results {
  padding: 0.9375rem 0;
}
.tx-kesearch-pi1 .result-list-item {
  padding-bottom: 1.875rem;
}
.tx-kesearch-pi1 .kesearch_pagebrowser ul li {
  list-style-type: none;
  display: inline-block;
}
.tx-kesearch-pi1 .kesearch_pagebrowser ul li:first-child {
  padding-left: 0;
}
.tx-kesearch-pi1 .kesearch_pagebrowser ul li:before {
  display: none;
  content: " ";
}
.tx-kesearch-pi1 .result-teaser p {
  display: inline-block;
}

.body-content {
  text-align: center;
  padding-top: 7.5rem;
  padding-bottom: 10px;
  min-height: 100vh;
  background: #fff;
}
@media only screen and (max-width: 1000px) {
  .body-content {
    padding-top: 5.5rem;
  }
}

article {
  margin: 0.9375rem 0;
}
article.with-image .content-image {
  margin-top: 0.46875rem;
  float: left;
  display: inline-block;
}
article.with-image .content-image + p {
  margin-top: 0.46875rem;
}
@media only screen and (min-width: 641px) {
  article.with-image .content-image {
    max-width: 40%;
    margin-right: 0.3125rem;
  }
}
article.with-image p {
  display: inline-block;
  vertical-align: top;
}

.page-wrapper {
  width: 100%;
  padding: 0 0.9375rem;
  position: relative;
  text-align: left;
  display: inline-block;
}
.page-wrapper.center {
  text-align: center;
}
@media only screen and (max-width: 640px) {
  .page-wrapper {
    padding: 0 0.46875rem;
  }
}
@media only screen and (max-width: 1000px) {
  .page-wrapper.mobile-scale-3 {
    width: 300%;
  }
}

@media only screen and (max-width: 640px) {
  section > h1, section > h2, section > h3, section > h4, section > h5, section > h6, article > h1, article > h2, article > h3, article > h4, article > h5, article > h6, .page-wrapper > h1, .page-wrapper > h2, .page-wrapper > h3, .page-wrapper > h4, .page-wrapper > h5, .page-wrapper > h6 {
    padding-left: 0.9375rem;
    padding-right: 0.9375rem;
  }
}
.select-boxes .multi-select-selection {
  margin-top: 0.9375rem;
  line-height: 1rem;
}
.select-boxes .multi-select-selection .row + .row {
  margin-top: 0.46875rem;
}
@media only screen and (max-width: 1000px) {
  .select-boxes .multi-select-selection .column-m-auto {
    margin-left: .5rem;
  }
}
.select-boxes .multi-select-option {
  background: #ebebeb;
  color: white;
  text-transform: uppercase;
  text-align: center;
  cursor: pointer;
  font-family: "Open Sans", Arial;
  font-weight: 700;
}
.select-boxes .multi-select-option.active {
  background: #e0151d;
}
.select-boxes .multi-select-item {
  display: none;
}
.select-boxes .multi-select-item.active {
  display: block;
}

.center table {
  margin: auto;
}

.right table {
  float: right;
}

@media only screen and (max-width: 640px) {
  .column-m-100 .right, .column-m-100 .center, .column-m-100 .left {
    clear: both;
    float: none;
    margin: auto;
  }
}
@media only screen and (max-width: 640px) {
  .column-s-100 .right, .column-s-100 .center, .column-s-100 .left {
    clear: both;
    float: none;
    margin: auto;
  }
}
p + table {
  margin-top: 1rem;
}

@media only screen and (max-width: 1000px) {
  .table-container {
    overflow-x: scroll;
  }
}

table {
  width: 100%;
  word-break: normal;
  border-collapse: collapse !important;
  background: transparent;
}
table th, table td, table th {
  padding: 0.9375rem;
  border-top: 1px solid #b6b6b6;
  border-bottom: 1px solid #b6b6b6;
  text-align: left;
}
table td.center, table th.center {
  text-align: center;
}
table td.left, table th.left {
  text-align: left;
}
table td.right, table th.right {
  text-align: right;
}
table thead {
  background: #ebebeb;
  color: #b11117;
  font-family: "Open Sans", Arial;
  font-weight: 700;
}
table tbody {
  font-family: Verdana, Arial, Arial;
}
table.full-size {
  width: 100%;
}
table.center {
  text-align: center;
}
table.jobs {
  color: black;
}
table.jobs td:nth-child(1) {
  width: 45%;
}
table.jobs td:nth-child(2) {
  width: 30%;
  text-align: center;
}
table.jobs tbody td:nth-child(2) {
  color: #646464;
}
table.jobs td:nth-child(3) {
  width: 25%;
  text-align: center;
}
@media only screen and (max-width: 1000px) {
  table.jobs thead {
    display: none;
  }
  table.jobs td {
    display: inline-block;
    width: 50% !important;
    border: 0;
    text-align: left !important;
    font-size: 1rem;
  }
  table.jobs td:first-child {
    width: 100% !important;
    padding-bottom: 0;
  }
  table.jobs td:last-child {
    text-align: right !important;
  }
  table.jobs td span {
    display: inline;
  }
  table.jobs tr {
    font-size: 0;
    display: block;
    border: 1px solid #646464;
    margin-top: 0.9375rem;
  }
}
@media only screen and (max-width: 640px) {
  table.jobs td {
    width: 100% !important;
    text-align: left;
  }
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0 0 0 0;
  padding: 0 0 0 0;
}

h1 {
  font-size: 2rem;
  min-height: 2rem;
  color: #b11117;
  font-family: "Open Sans", Arial;
  text-decoration: none;
  font-style: normal;
  text-align: left;
}

h2 {
  font-size: 1.5rem;
  min-height: 1.5rem;
  color: #b11117;
  font-family: "Open Sans", Arial;
  text-decoration: none;
  font-style: normal;
  text-align: left;
}

h3 {
  font-size: 1.1rem;
  min-height: 1.1rem;
  color: #b11117;
  font-family: "Open Sans", Arial;
  text-decoration: none;
  font-style: normal;
  text-align: left;
}

h4 {
  font-size: calc(2.5 - ((4-1) * .25))rem;
  min-height: calc(2.5 - ((4-1) * .25))rem;
  color: #b11117;
  font-family: "Open Sans", Arial;
  text-decoration: none;
  font-style: normal;
  text-align: left;
}

h5 {
  font-size: calc(2.5 - ((5-1) * .25))rem;
  min-height: calc(2.5 - ((5-1) * .25))rem;
  color: white;
  font-family: "Open Sans", Arial;
  text-decoration: none;
  font-style: normal;
  text-align: left;
}

h6 {
  font-size: calc(2.5 - ((6-1) * .25))rem;
  min-height: calc(2.5 - ((6-1) * .25))rem;
  color: #b11117;
  font-family: "Open Sans", Arial;
  text-decoration: none;
  font-style: normal;
  text-align: left;
}

.row + h1, .row + h2, .row + h3, .row + h4, .row + h5, .row + h6 {
  margin-top: 1.875rem;
}

p {
  margin-top: -.4rem;
  color: black;
  font-size: 1rem;
  line-height: 1.6rem;
}
p + p {
  margin-top: .5rem;
}
h1 + p, h2 + p, h3 + p, h4 + p, h5 + p {
  margin-top: 0.234375rem;
}

ul, ol {
  padding: 0;
  list-style-type: none;
}

.page-wrapper:not(.page-menu) ol {
  display: inline-block;
  list-style-type: none;
}
.page-wrapper:not(.page-menu) ol li {
  counter-increment: step-counter;
}
.page-wrapper:not(.page-menu) ol li:before {
  content: counter(step-counter) ".";
  margin-right: 5px;
  color: black;
}
.page-wrapper:not(.page-menu) ol ol li {
  counter-increment: step-counter-2;
}
.page-wrapper:not(.page-menu) ol ol li:before {
  content: counter(step-counter) "." counter(step-counter-2) ".";
  margin-right: 5px;
  padding-left: 1rem;
  color: #4d4d4d;
}
.page-wrapper:not(.page-menu) ol ol li ol li {
  counter-increment: step-counter-3;
}
.page-wrapper:not(.page-menu) ol ol li ol li:before {
  content: counter(step-counter) "." counter(step-counter-2) "." counter(step-counter-3) ".";
  margin-right: 5px;
  padding-left: 2rem;
  color: #333333;
}
.page-wrapper:not(.page-menu) ul {
  display: inline-block;
}
.page-wrapper:not(.page-menu) ul li {
  position: relative;
  list-style-type: none;
  padding-left: 1rem;
  line-height: 1.6rem;
  margin-bottom: .6rem;
}
.page-wrapper:not(.page-menu) ul li:before {
  content: "\2013";
  position: absolute;
  left: 0rem;
  font-family: "Open Sans", Arial, Times New Roman;
  top: 0;
  color: black;
}
.page-wrapper:not(.page-menu) ul li + li {
  margin-top: .5rem;
}
.page-wrapper:not(.page-menu) ul li ul {
  padding-left: 1rem;
}
.page-wrapper:not(.page-menu) ul li ul li:before {
  color: #4d4d4d;
}
.page-wrapper:not(.page-menu) ul li ul li ul {
  padding-left: 1rem;
}
.page-wrapper:not(.page-menu) ul li ul li ul li:before {
  color: gray;
}

span.hint {
  font-size: .9rem;
  color: #646464;
}

q {
  font-style: italic;
}

.spacing {
  padding: 0 0.9375rem;
}

.font-primary {
  font-family: Verdana, Arial;
}

.font-secondary {
  font-family: "Open Sans", Arial;
}

.sub-heading {
  font-size: .9rem;
  font-weight: bold;
  color: #0a0a0a;
  font-style: italic;
  font-family: "Times New Roman";
}

.keyword {
  width: 100%;
  display: inline-block;
  font-size: 1rem;
}

.keyword-title {
  background: #b11117;
  display: block;
  width: 100%;
  font-size: 1.5rem;
  padding: .5rem;
  text-align: center;
  font-weight: bold;
  height: 3rem;
}
.keyword-title p {
  color: white;
  line-height: 3rem;
}

.keyword-item {
  background: #ebebeb;
  display: block;
  width: 100%;
  text-align: left;
}
.keyword-item ul {
  padding: .5rem 1rem;
}
.keyword-item ul li {
  width: 100%;
  padding-left: 1.7rem !important;
}
.keyword-item ul li:before {
  content: "\f238" !important;
  font-family: "FontAwesome" !important;
  color: #b11117 !important;
}

@-webkit-keyframes pulse {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(1.2);
  }
}
@-moz-keyframes pulse {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(1.2);
  }
}
@-o-keyframes pulse {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(1.2);
  }
}
@keyframes pulse {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(1.2);
  }
}
.lb-box-background {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 99999;
  background-color: rgba(0, 0, 0, 0.4);
  cursor: pointer;
}
.lb-box-background .lb-box-content {
  width: 100px;
  height: 120px;
  box-sizing: content-box;
  margin: 0;
  background: #fff;
  position: absolute;
  top: 50%;
  left: 50%;
  padding: 1rem;
  margin-right: -50%;
  transform: translate(-50%, -50%);
  border: #777 solid 1px;
  box-shadow: 0 0 5px #333;
  cursor: default;
  -webkit-transition: all 0.6s ease-out;
  transition: all 0.6s ease-out;
}
.lb-box-background .lb-box-content .lb-box-closing {
  position: absolute;
  width: 1.5rem;
  height: 1.5rem;
  border-radius: 0;
  border: 0px solid #fff;
  top: 0;
  right: -0.5rem;
  margin-top: -0.75rem;
  background-color: white;
  color: white;
  text-align: center;
  font-size: 1rem;
  cursor: pointer;
  font-family: Verdana;
  transition: all .3s ease-in-out;
}
.lb-box-background .lb-box-content .lb-box-closing:hover {
  background-color: white;
}
.lb-box-background .lb-box-content .lb-box-imgbox {
  overflow: hidden;
}
.lb-box-background .lb-box-content .loading {
  position: relative;
  height: 100px;
  width: 100px;
  background-size: contain;
  border-radius: 0;
  background-repeat: no-repeat;
  animation-duration: .5s;
  animation-direction: alternate;
  animation-iteration-count: infinite;
  -webkit-animation: pulse;
  -moz-animation: pulse;
  -ms-animation: pulse;
  -o-animation: pulse;
  animation: pulse;
}
.lb-box-background .lb-box-content .loading div {
  width: 100%;
  height: 100%;
  border-radius: 0%;
  background-color: rgba(0, 0, 0, 0);
}
.lb-box-background .lb-box-content .loadingText {
  padding-top: .5rem;
  text-align: center;
}

.scroll-x-indicator {
  text-align: center;
}

.scroll-indicator-point {
  display: inline-block;
  height: .75rem;
  width: .75rem;
  margin: 0 0.9375rem;
  border-radius: 100%;
  border: 1px solid #646464;
  cursor: pointer;
  transition: background 300ms ease-in-out;
}
.scroll-indicator-point.active {
  background: #646464;
}

/* Magnific Popup CSS */
.mfp-bg {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1042;
  overflow: hidden;
  position: fixed;
  background: #0b0b0b;
  opacity: 0.8;
}

.mfp-wrap {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1043;
  position: fixed;
  outline: none !important;
  -webkit-backface-visibility: hidden;
}

.mfp-container {
  text-align: center;
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  padding: 0 8px;
  box-sizing: border-box;
}

.mfp-container:before {
  content: '';
  display: inline-block;
  height: 100%;
  vertical-align: middle;
}

.mfp-align-top .mfp-container:before {
  display: none;
}

.mfp-content {
  position: relative;
  display: inline-block;
  vertical-align: middle;
  margin: 0 auto;
  text-align: left;
  z-index: 1045;
}

.mfp-inline-holder .mfp-content,
.mfp-ajax-holder .mfp-content {
  width: 100%;
  cursor: auto;
}

.mfp-ajax-cur {
  cursor: progress;
}

.mfp-zoom-out-cur, .mfp-zoom-out-cur .mfp-image-holder .mfp-close {
  cursor: -moz-zoom-out;
  cursor: -webkit-zoom-out;
  cursor: zoom-out;
}

.mfp-zoom {
  cursor: pointer;
  cursor: -webkit-zoom-in;
  cursor: -moz-zoom-in;
  cursor: zoom-in;
}

.mfp-auto-cursor .mfp-content {
  cursor: auto;
}

.mfp-close,
.mfp-arrow,
.mfp-preloader,
.mfp-counter {
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
}

.mfp-loading.mfp-figure {
  display: none;
}

.mfp-hide {
  display: none !important;
}

.mfp-preloader {
  color: #CCC;
  position: absolute;
  top: 50%;
  width: auto;
  text-align: center;
  margin-top: -0.8em;
  left: 8px;
  right: 8px;
  z-index: 1044;
}
.mfp-preloader a {
  color: #CCC;
}
.mfp-preloader a:hover {
  color: #FFF;
}

.mfp-s-ready .mfp-preloader {
  display: none;
}

.mfp-s-error .mfp-content {
  display: none;
}

button.mfp-close, button.mfp-arrow {
  overflow: visible;
  cursor: pointer;
  background: transparent;
  border: 0;
  -webkit-appearance: none;
  display: block;
  outline: none;
  padding: 0;
  z-index: 1046;
  box-shadow: none;
  touch-action: manipulation;
}
button::-moz-focus-inner {
  padding: 0;
  border: 0;
}

.mfp-close {
  width: 44px;
  height: 44px;
  line-height: 44px;
  position: absolute;
  right: 0;
  top: 0;
  text-decoration: none;
  text-align: center;
  opacity: 0.65;
  padding: 0 0 18px 10px;
  color: #FFF;
  font-style: normal;
  font-size: 28px;
  font-family: Arial, Baskerville, monospace;
}
.mfp-close:hover, .mfp-close:focus {
  opacity: 1;
}
.mfp-close:active {
  top: 1px;
}

.mfp-close-btn-in .mfp-close {
  color: #333;
}

.mfp-image-holder .mfp-close,
.mfp-iframe-holder .mfp-close {
  color: #FFF;
  right: -6px;
  text-align: right;
  padding-right: 6px;
  width: 100%;
}

.mfp-counter {
  position: absolute;
  top: 0;
  right: 0;
  color: #CCC;
  font-size: 12px;
  line-height: 18px;
  white-space: nowrap;
}

.mfp-arrow {
  position: absolute;
  opacity: 0.65;
  margin: 0;
  top: 50%;
  margin-top: -55px;
  padding: 0;
  width: 90px;
  height: 110px;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}
.mfp-arrow:active {
  margin-top: -54px;
}
.mfp-arrow:hover, .mfp-arrow:focus {
  opacity: 1;
}
.mfp-arrow:before, .mfp-arrow:after {
  content: '';
  display: block;
  width: 0;
  height: 0;
  position: absolute;
  left: 0;
  top: 0;
  margin-top: 35px;
  margin-left: 35px;
  border: medium inset transparent;
}
.mfp-arrow:after {
  border-top-width: 13px;
  border-bottom-width: 13px;
  top: 8px;
}
.mfp-arrow:before {
  border-top-width: 21px;
  border-bottom-width: 21px;
  opacity: 0.7;
}

.mfp-arrow-left {
  left: 0;
}
.mfp-arrow-left:after {
  border-right: 17px solid #FFF;
  margin-left: 31px;
}
.mfp-arrow-left:before {
  margin-left: 25px;
  border-right: 27px solid #3F3F3F;
}

.mfp-arrow-right {
  right: 0;
}
.mfp-arrow-right:after {
  border-left: 17px solid #FFF;
  margin-left: 39px;
}
.mfp-arrow-right:before {
  border-left: 27px solid #3F3F3F;
}

.mfp-iframe-holder {
  padding-top: 40px;
  padding-bottom: 40px;
}
.mfp-iframe-holder .mfp-content {
  line-height: 0;
  width: 100%;
  max-width: 900px;
}
.mfp-iframe-holder .mfp-close {
  top: -40px;
}

.mfp-iframe-scaler {
  width: 100%;
  height: 0;
  overflow: hidden;
  padding-top: 56.25%;
}
.mfp-iframe-scaler iframe {
  position: absolute;
  display: block;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.6);
  background: #000;
}

/* Main image in popup */
img.mfp-img {
  width: auto;
  max-width: 100%;
  height: auto;
  display: block;
  line-height: 0;
  box-sizing: border-box;
  padding: 40px 0 40px;
  margin: 0 auto;
}

/* The shadow behind the image */
.mfp-figure {
  line-height: 0;
}
.mfp-figure:after {
  content: '';
  position: absolute;
  left: 0;
  top: 40px;
  bottom: 40px;
  display: block;
  right: 0;
  width: auto;
  height: auto;
  z-index: -1;
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.6);
  background: #444;
}
.mfp-figure small {
  color: #BDBDBD;
  display: block;
  font-size: 12px;
  line-height: 14px;
}
.mfp-figure figure {
  margin: 0;
}

.mfp-bottom-bar {
  position: absolute;
  bottom: 40px;
  opacity: 0;
  transition: opacity 0.3s 0.1s;
  margin-top: 0;
  top: auto;
  padding: 10px 0;
  left: 0;
  width: 100%;
  cursor: auto;
}

.mfp-title {
  text-align: left;
  line-height: 18px;
  color: #F3F3F3;
  word-wrap: break-word;
  padding-right: 36px;
}

.mfp-content:hover .mfp-bottom-bar {
  opacity: 1;
}

.mfp-image-holder .mfp-content {
  max-width: 100%;
}

.mfp-gallery .mfp-image-holder .mfp-figure {
  cursor: pointer;
}

@media screen and (max-width: 800px) and (orientation: landscape), screen and (max-height: 300px) {
  /**
   * Remove all paddings around the image on small screen
   */
  .mfp-img-mobile .mfp-image-holder {
    padding-left: 0;
    padding-right: 0;
  }
  .mfp-img-mobile img.mfp-img {
    padding: 0;
  }
  .mfp-img-mobile .mfp-figure:after {
    top: 0;
    bottom: 0;
  }
  .mfp-img-mobile .mfp-figure small {
    display: inline;
    margin-left: 5px;
  }
  .mfp-img-mobile .mfp-bottom-bar {
    background: rgba(0, 0, 0, 0.6);
    bottom: 0;
    margin: 0;
    top: auto;
    padding: 3px 5px;
    position: fixed;
    box-sizing: border-box;
  }
  .mfp-img-mobile .mfp-bottom-bar:empty {
    padding: 0;
  }
  .mfp-img-mobile .mfp-counter {
    right: 5px;
    top: 3px;
  }
  .mfp-img-mobile .mfp-close {
    top: 0;
    right: 0;
    width: 35px;
    height: 35px;
    line-height: 35px;
    background: rgba(0, 0, 0, 0.6);
    position: fixed;
    text-align: center;
    padding: 0;
  }
}
@media all and (max-width: 900px) {
  .mfp-arrow {
    -webkit-transform: scale(0.75);
    transform: scale(0.75);
  }

  .mfp-arrow-left {
    -webkit-transform-origin: 0;
    transform-origin: 0;
  }

  .mfp-arrow-right {
    -webkit-transform-origin: 100%;
    transform-origin: 100%;
  }

  .mfp-container {
    padding-left: 6px;
    padding-right: 6px;
  }
}
/* Slider */
.slick-slider {
  position: relative;
  display: block;
  box-sizing: border-box;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent;
}

.slick-list {
  position: relative;
  overflow: hidden;
  display: block;
  margin: 0;
  padding: 0;
}
.slick-list:focus {
  outline: none;
}
.slick-list.dragging {
  cursor: pointer;
  cursor: hand;
}

.slick-slider .slick-track,
.slick-slider .slick-list {
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}

.slick-track {
  position: relative;
  left: 0;
  top: 0;
  display: table;
}
.slick-track:before, .slick-track:after {
  content: "";
  display: table;
}
.slick-track:after {
  clear: both;
}
.slick-loading .slick-track {
  visibility: hidden;
}

.slick-slide {
  height: 100%;
  display: table-cell;
  vertical-align: middle;
  min-height: 1px;
  display: none;
}
[dir="rtl"] .slick-slide {
  float: right;
}
.slick-slide img {
  display: block;
}
.slick-slide.slick-loading img {
  display: none;
}
.slick-slide.dragging img {
  pointer-events: none;
}
.slick-initialized .slick-slide {
  display: table-cell;
}
.slick-loading .slick-slide {
  visibility: hidden;
}
.slick-vertical .slick-slide {
  display: block;
  height: auto;
  border: 1px solid transparent;
}

.slick-arrow.slick-hidden {
  display: none;
}

.galley-wrapper .slick-prev {
  left: 0px;
  z-index: 100;
  margin-top: 0 !important;
}
.galley-wrapper .slick-next {
  right: 0px;
  margin-top: 0 !important;
}

/* Slider */
.slick-loading .slick-list {
  background: #fff url("../images/ajax-loader.gif") center center no-repeat;
}

/* Icons */
/* Arrows */
.slick-prev,
.slick-next {
  position: absolute;
  display: block;
  height: 100px;
  width: 50px;
  line-height: 0px;
  font-size: 0px;
  cursor: pointer;
  background: transparent;
  color: transparent;
  top: 50%;
  -webkit-transform: translate(0, -50%);
  -ms-transform: translate(0, -50%);
  transform: translate(0, -50%);
  padding: 0;
  border: none;
  outline: none;
  transition: all 300ms ease-in-out;
}
.slick-prev:hover, .slick-prev:focus,
.slick-next:hover,
.slick-next:focus {
  outline: none;
  color: transparent;
}
.slick-prev:hover:before, .slick-prev:focus:before,
.slick-next:hover:before,
.slick-next:focus:before {
  opacity: 1;
}
.slick-prev.slick-disabled:before,
.slick-next.slick-disabled:before {
  opacity: 0.25;
}
.slick-prev:before,
.slick-next:before {
  font-family: "FontAwesome";
  font-size: 30px;
  line-height: 1;
  color: white;
  opacity: 0.75;
  padding: 10px 13px 10px 16px;
  border-radius: 50%;
  background: rgba(177, 17, 23, 0.5);
  transition: all 300ms ease-in-out;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.slick-prev {
  left: 15px;
  z-index: 100;
}
[dir="rtl"] .slick-prev {
  left: auto;
  right: -35px;
}
.slick-prev:before {
  content: "";
  padding: 10px 16px 10px 13px;
}
[dir="rtl"] .slick-prev:before {
  content: "";
}

.slick-next {
  right: 15px;
  z-index: 100;
}
[dir="rtl"] .slick-next {
  left: -35px;
  right: auto;
}
.slick-next:before {
  content: "";
}
[dir="rtl"] .slick-next:before {
  content: "";
}

/* Dots */
.slick-dots {
  position: relative;
  margin-top: 1rem;
  list-style: none;
  display: block;
  text-align: center;
  padding: 0;
  margin: 0;
  width: 100%;
}
.slick-dots li {
  position: relative;
  display: inline-block;
  height: 40px;
  width: 40px;
  margin: 1rem 5px 0;
  padding: 0;
  cursor: pointer;
}
.slick-dots li button {
  position: relative;
  border: 0;
  background: transparent;
  display: block;
  height: 40px;
  width: 40px;
  outline: none;
  line-height: 0px;
  font-size: 0px;
  color: transparent;
  padding: 5px;
  cursor: pointer;
}
.slick-dots li button:hover, .slick-dots li button:focus {
  outline: none;
}
.slick-dots li button:hover:before, .slick-dots li button:focus:before {
  opacity: 1;
}
.slick-dots li button:before {
  position: absolute;
  top: 0;
  left: 0;
  content: "";
  width: 40px;
  height: 40px;
  font-family: "FontAwesome";
  font-size: 10px;
  line-height: 40px;
  text-align: center;
  color: white;
  opacity: 0.25;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.slick-dots li.slick-active button:before {
  position: absolute;
  color: white;
  opacity: 0.75;
}

.slick-slider ul li:before {
  position: absolute;
  content: "";
}

.slick-track {
  padding: 0.9375rem 0;
}

.card-slider-wrapper.full .slick-track {
  padding: 0;
}
